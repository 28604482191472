import {SttFilterModel} from "@/features/project-stt-settings/types";
import {toArray} from "lodash";
import {getSecondsFromMmSsString, renderDurationWithDots, secondsToMmSs} from "@/services/utils";

export function isSttFilterEmpty(filter: SttFilterModel) {
    const values = [
        filter.managers?.length ? filter.managers : null,
        ...toArray(filter.started),
        ...toArray(filter.duration)
    ]

    return !values.some(i => i !== null)
}

export type SttFilterLocalModel = {
    managers: number[]
    duration: {
        min: string,
        max: string
    },
    started: {
        from: string | null,
        to: string | null
    }
}

export function getDefaultFilter(): SttFilterLocalModel {
    return {
        managers: [],
        duration: {
            min: ``,
            max: ``
        },
        started: {
            from: null,
            to: null
        }
    }
}

export function sttFilter2LocalModel(model: SttFilterModel | null): SttFilterLocalModel | null {
    if (model === null) return null
    return {
        managers: model.managers ?? [],
        duration: {
            min: model.duration.min !== null ? secondsToMmSs(model.duration.min) : ``,
            max: model.duration.max !== null ? secondsToMmSs(model.duration.max) : ``
        },
        started: model.started
    } as SttFilterLocalModel
}

export function sttFilterLocal2Request(model: SttFilterLocalModel):SttFilterModel {
    return {
        managers: model.managers && model.managers.length ? model.managers : null,
        duration: {
            min: model.duration.min.length ? getSecondsFromMmSsString(model.duration.min) : null,
            max: model.duration.max.length ? getSecondsFromMmSsString(model.duration.max) : null
        },
        started: model.started
    }
}
